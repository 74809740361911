<template>
  <b-container class="px-1">
      <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
      <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div> 
      <div v-else-if="appState=='ready'">
      <b-container fluid>
        <b-row class="my-1" v-for="pkey in playerKeysComputed" :key="pkey">
          <b-col sm="5"><label :for="`pkey-${pkey}`"> {{ pkey }}:</label></b-col>
          <b-col sm="7">
            <b-form-checkbox v-if="pkey === 'send_update_mail' || pkey === 'is_active'"
              :id="`pkey-${pkey}`"
              :data-test-id="'frm-input-' + pkey"
              :disabled="true"
              v-model="player[pkey]">
      
            </b-form-checkbox>
            <b-form-input v-else
              :id="`pkey-${pkey}`"
              :data-test-id="'frm-input-' + pkey"
              :readonly="true"
              v-model="player[pkey]">
            </b-form-input>
          </b-col>
        </b-row>
      </b-container>
      </div>
      <!-- <b-button @click="resetPlayer()">Reset</b-button> -->
  </b-container>
 </template>

<script>
import Utils,{ makeCopy } from '../helper/Utils'
import ModelUtils from '../helper/ModelUtils'
import DBAPI from '../db/dbapi'


export default {
  name: 'UserView',
  mounted() {
    
    this.processParams()
    this.processQuery()
    this.getData()
  },
  watch: {
    '$route': function(newRoute, oldRoute) {
      this.processParams()
      this.processQuery()
    },
  },
  data () {
    return {
      appState: 'loading',
      userId:"",
      player: {},
      playerOrig: {}, // for cancel edit (future use)
      allEmailInput:"",
    }
  },
  computed: {
    playerKeysComputed() {
      var player = this.player;
      if (!player) return []
      // console.log(players)
      var keys = Object.keys(player);
      var retVal = []
      keys.forEach(function(prop) {
      if (prop.toString().length>=2) {
          retVal.push(prop)
        }
      });
      return retVal
    },
  },
  methods: {
    getData: function() {
      var self = this

      var userData = this.$$$userData()
      let userId = userData.user_id
      if (!userId) {
        console.error("nicht angemeldet!")
        self.appState = 'error'
        return
      }
      
      
      var params = {model:"Player", keyName:"user_id", key:this.userId}  // TODO: team_nr eingeben lassen

      DBAPI.getFindOne(params).then(function(response) {
          if (response.data.error){
            //app.errorMessage = response.data.message;
            console.error(response.data)
					}	else {
            // console.log(response.data)
            self.playerOrig = response.data
            self.player = makeCopy(self.playerOrig)
            self.appState = 'ready'
          }       
      });  // TODO: catch
    },
    resetPlayer() {
      this.player = makeCopy(this.playerOrig)
    },
    goBack: function() {
      this.$router.go(-1);
    },
    processParams() {

      if (this.$route.params) {
        this.userId = this.$route.params.userId
      } 
    },
    
    processQuery() {
      if (this.$route.query) {
        if (this.$route.query.userId) {
          
        }
      }
    },
    test() {
      console.log(this.player)
    }
  }
}  
   
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scope>
/* ol li {
  background-color: #333;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 2px;
  padding: 6px 6px;
} */

/* ol li span {
  color: #999;
  float: right;
} */
ol li.scale-enter-active, ol li.scale-move, ol li.scale-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
ol li.scale-enter {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
ol li.scale-leave-active {
  opacity: 0;
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
}
</style>
